<template>
  <FormWrapper
    :form="form"
    :hideForm="hideForm"
    :handleOpened="() => handleProps({ key: 'opened', value: !form.opened })"
  >
    <b-row>
      <b-col class="exam-request">
        <validation-observer
          :ref="`${form.id}-form`"
          v-slot="{ invalid }"
          v-if="canEdit"
        >
          <div class="form-group">
            <validation-provider
              name="patologia"
              rules="required"
              v-slot="{ touched, errors }"
            >
              <multiselect
                v-if="!fields.custom"
                v-model="fields.patologia"
                :options="antecedentes"
                :option-height="40"
                :showLabels="false"
                :searchable="true"
                :allowEmpty="false"
                :showNoResults="true"
                placeholder="Pesquisar patologia"
                class="search-mode with-border"
                :class="{ error: touched && errors.length > 0 && 1 === 2 }"
              >
                <template slot="caret">
                  <div class="search">
                    <Search />
                  </div>
                </template>
                <template slot="noResult" slot-scope="props">
                  <li @click="setCustom(true, props.search)">
                    <div class="multiselect__option custom-item">
                      <Plus class="icon" />
                      Adicionar patologia "{{ props.search }}"
                    </div>
                  </li>
                </template>
                <template slot="noOptions"> Nenhuma opção </template>
              </multiselect>
              <div class="custom-input" v-else>
                <b-button variant="link" @click="setCustom(false)">
                  Selecionar um item da lista
                </b-button>
                <input
                  autocomplete="off"
                  v-model="fields.patologia"
                  :readonly="!canEdit"
                  type="text"
                  class="form-control"
                  placeholder=""
                  :class="{ error: touched && errors.length > 0 && 1 === 2 }"
                />
              </div>
            </validation-provider>
          </div>

          <div class="laterality-container">
            <p>Lateralidade</p>
            <div class="laterality-content">
              <div class="laterality-input">
                <div class="laterality-option">
                  <input
                    type="radio"
                    name="surgery-data-laterality"
                    value="Olho esquerdo"
                    v-model="fields.lateralidadePatologia"
                  />Olho esquerdo
                </div>
                <div class="laterality-option">
                  <input
                    type="radio"
                    name="surgery-data-laterality"
                    value="Olho direito"
                    v-model="fields.lateralidadePatologia"
                  />Olho direito
                </div>
                <div class="laterality-option">
                  <input
                    type="radio"
                    name="surgery-data-laterality"
                    value="Ambos os olhos"
                    v-model="fields.lateralidadePatologia"
                  />Ambos os olhos
                </div>
              </div>

              <b-button
                variant="primary"
                class="button"
                :disabled="invalid"
                @click="addItem"
                >Adicionar
              </b-button>
            </div>
          </div>
        </validation-observer>

        <b-row>
          <b-col>
            <table class="table-eyecare">
              <thead>
                <tr>
                  <th>Patologia</th>
                  <th>Lateralidade</th>
                  <th>Comentário</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(value, index) of form.value" :key="index">
                  <td>{{ value.patologia }}</td>
                  <td>{{ value.lateralidadePatologia }}</td>

                  <td>
                    <div v-if="value.editing !== true">
                      <b-button
                        variant="link"
                        v-if="value.comentario === null"
                        class="p-0"
                        @click="editItem(index)"
                        :disabled="!canEdit"
                      >
                        Adicionar comentário
                      </b-button>
                      <span
                        v-html="value.comentario"
                        class="vue_edition"
                      />
                    </div>
                    <div class="form-group mb-0" v-else>
                      <VueEditor
                        id="template"
                        class="vue_edition"
                        v-model="fields.comentario"
                        :editorToolbar="customToolbar"
                        :disabled="!canEdit"
                      />
                      <b-button
                        variant="primary"
                        class="mt-8"
                        @click="saveComment(index)"
                      >
                        Salvar
                      </b-button>
                    </div>
                  </td>

                  <td class="text-right">
                    <div class="more-with-menu">
                      <MoreVertical class="more-icon" />
                      <div class="menu">
                        <b-button
                          variant="link"
                          @click="editItem(index)"
                          :disabled="!canEdit"
                        >
                          Editar
                        </b-button>
                        <b-button
                          variant="link remove mb-0"
                          @click="removeItem(index)"
                          :disabled="!canEdit"
                        >
                          Remover
                        </b-button>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </FormWrapper>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import FormWrapper from '@/components/Attendance/Forms/FormWrapper'
import Search from '@/assets/icons/search.svg'
import Plus from '@/assets/icons/plus.svg'
import MoreVertical from '@/assets/icons/more-vertical.svg'
import { VueEditor } from "vue2-editor"

export default {
  components: {
    FormWrapper,
    Search,
    Plus,
    MoreVertical,
    VueEditor,
  },
  computed: {
    ...mapState({
      canEdit: state => state.attendance.canEdit,
      form: state => state.attendance.form.patologiaASerTratada,
    })
  },
  inject: ['antecedentes'],
  data() {
    return {
      editingIndex: null,
      fields: this.getDefaultFields(),
      customToolbar: [
          [{ header: [false, 1, 2, 3, 4, 5, 6] }],
          ["bold", "underline", "strike"], // toggled buttons
          [
            { align: "" },
            { align: "center" },
            { align: "right" },
            { align: "justify" }
          ],
          ["blockquote", "code-block"],
          [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
          [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
          [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        ],
    }
  },
  methods: {
    ...mapActions('attendance/form', ['updateMedicalRecord', 'destroyMedicalRecord']),
    ...mapActions('attendance/form/patologiaASerTratada', ['handleFields', 'handleProps']),
    getDefaultFields() {
      return {
        custom: false,
        patologia: null,
        lateralidadePatologia: 'Ambos os olhos',
        comentario: null
      }
    },
    setCustom(value, searchTerm) {
      this.fields.custom = value
      this.fields.patologia = value ? searchTerm : null
    },
    selectProducts() {
      this.products = []
    },
    async searchProducts(query) {
      if (query.length > 2) {
        this.products = []
        try {
          await this.getProducts(query)
        } catch (error) {
          this.$toast.error(error.message)
        }
      }
    },
    async getProducts(query) {
      try {
        const response = await this.api.getProducts(this.clinic.id, null, query)
        const products = response.data.data.map(data => {
          return data.name
        })

        this.products = products.filter(function (a) {
          return !this[JSON.stringify(a)] && (this[JSON.stringify(a)] = true)
        }, Object.create(null))
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    addItem() {
      const value = [...this.form.value]
      if (this.editingIndex !== null) {
        value.splice(this.editingIndex, 1)
      }

      value.push(this.fields)
      this.handleProps({ key: 'value', value })
      this.updateMedicalRecord(this.form)
      this.fields = this.getDefaultFields()
      this.editingIndex = null
    },
    saveComment(index) {
      const value = [...this.form.value]
      value[index].comentario = this.fields.comentario
      delete value[index].editing
      this.fields = this.getDefaultFields()
      this.handleProps({ key: 'value', value })
      this.updateMedicalRecord(this.form)
    },
    editItem(index) {
      const value = [...this.form.value]
      this.fields = {
        ...this.getDefaultFields(),
        comentario: value[index].comentario
      }
      value[index].editing = true
      this.handleProps({ key: 'value', value })
    },
    removeItem(index) {
      const value = [...this.form.value]
      value.splice(index, 1)
      this.handleProps({ key: 'value', value })
      this.updateMedicalRecord(this.form)
    },
    clearEditable() {
      setTimeout(() => {
        const value = [...this.form.value]
        value.map(v => v.editing && delete v.editing)
        this.handleProps({ key: 'value', value })
        this.fields = this.getDefaultFields()
      }, 500)
    },
    hideForm(){
      this.handleProps({ key: 'showing', value: false })
      this.destroyMedicalRecord(this.form)
      this.form.value.forEach((key,index) => {
        this.removeItem(index)
      });
    }
  },
}
</script>
<style lang="scss" scoped>
.exam-request {
  display: flex !important;
  flex-direction: column !important;
  .multiselect {
    border: 1px solid #C6CEEB;
    border-radius: 8px;
  }
  .laterality-container {
    display: flex;
    flex-direction: column;
    p {
      font-family: 'Nunito Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 150%;
      color: #0C1D59;
    }
    .laterality-content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 14px;
      .laterality-input {
        display: flex;
        flex-direction: row;
        gap: 24px;
        .laterality-option {
          display: flex;
          flex-direction: row;
          gap: 12px;
          align-items: center;
          font-family: 'Nunito Sans';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 150%;
          color: var(--type-active);
          input {
            height: 20px;
            width: 20px;
          }
        }
      }
      .btn {
        width: auto !important;
      }
    }
  }
}
.table-eyecare {
  padding: 16px;
}
.multiselect {
  .custom-item {
    color: var(--blue-500);
    display: flex;
    align-items: center;
    font-weight: 600;
    .icon {
      width: 20px;
      height: 20px;
      fill: var(--blue-500);
      margin-right: 12px;
    }
  }
  .multiselect__single {
    @media(min-height: 1600){
      font-size: 1.6vh;
    }
    @media(max-height: 1600){
      font-size: 1vw;
    }
  }
}
.button {
  height: 38px;
}
.edit-area {
  display: flex;
  gap: 10px;
  align-items: end;
  button {
    height: 38px;
  }
}
.vue_edition {
  margin-bottom: 8px;
  word-break: break-all;
  background-color: white;

  :deep(.ql-editor) {
    min-height: 100px !important;
  }
}
</style>
